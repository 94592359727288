import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import IdentPinImg from '../../../images/ident_pin.jpg'

const paginaServizio = {
  titolo: 'Ident Pin',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: IdentPinImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'IdentPIN è un servizio ad alto valore aggiunto che tutela le tue spedizioni.',
        },
        {
          id: 2,
          testo:
            'Grazie ad IdentPIN solo il destinatario designato può ricevere la spedizione. La consegna, infatti, può avvenire solo dopo che il destinatario inserisce sul palmare dell’autista GLS un codice PIN di sblocco fornitogli preventivamente dal mittente. La consegna è pertanto vincolata alla conoscenza del codice PIN comunicato dal mittente al destinatario.',
        },
        {
          id: 3,
          testo: 'Con IdentPIN rendi le tue spedizioni personali e sicure.',
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo:
            'Il servizio IdentPIN certifica l’unico destinatario autorizzato a ritirare la spedizione',
        },
        {
          id: 2,
          testo: 'Unicamente mittente e destinatario conoscono il codice PIN',
        },
        {
          id: 3,
          testo:
            'In assenza del PIN, oppure al terzo inserimento errato, la consegna viene bloccata',
        },
        {
          id: 4,
          testo: 'Alto livello di sicurezza nel processo di consegna',
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
      ],
      servizi: null,
    },
  ],
}

const IdentPin = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default IdentPin
